import { postRequest, getRequest } from './index'

// 登录接口
export const ordersList = (data, successCallback, failureCallback) => {
  postRequest('/order_s/plat/list', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 列表接口详情
export const ordersDetails = (data, successCallback, failureCallback) => {
  getRequest('/order_s/findById', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
